'use client'

import * as React from 'react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { cn } from '~/utils/misc.tsx'

type SeparatorProps = {
  asChild?: boolean
  orientation?: 'horizontal' | 'vertical'
  decorative?: boolean
  textAlign?: 'left' | 'right'
  className?: string
  ref?: any
  children?: any
}

const Separator = React.forwardRef(
  (
    {
      children,
      textAlign = 'left',
      className,
      orientation = 'horizontal',
      decorative = true,
      ...props
    }: SeparatorProps,
    ref,
  ) =>
    children ? (
      <div className={`flex items-center gap-6 whitespace-nowrap`}>
        {textAlign === 'left' && children}
        <SeparatorPrimitive.Root
          ref={ref}
          decorative={decorative}
          orientation={orientation}
          className={cn(
            'bg-border',
            orientation === 'horizontal' ? 'w-full border' : 'h-full border',
            className,
          )}
          {...props}
        />
        {textAlign === 'right' && children}
      </div>
    ) : (
      <SeparatorPrimitive.Root
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={cn(
          'border-border',
          orientation === 'horizontal' ? 'w-full border' : 'h-auto border',
          className,
        )}
        {...props}
      />
    ),
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
